<template>
  <div>
    <v-tabs v-model="activeTab" class="mb-1" grow>
      <v-tab v-for="tab of tabs"
        :key="tab.index" @click="$router.push({path:tab.url})">{{tab.name}}</v-tab>
    </v-tabs>
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: "LandingTabs",
    props:['tab', 'landing'],
    data() {
      let tabs = [
        {index:0, name:'Informacion', url:`/backend/admin/cms/landings/${this.landing.id}/edit`},
        {index:1, name:'Imagenes', url:`/backend/admin/cms/landings/${this.landing.id}/images`},
      ]
      if (this.landing.type === 'tours') {
        tabs.push({index:2, name:'Extras', url:`/backend/admin/cms/landings/${this.landing.id}/extras`});
      }
      return {
        activeTab:null,
        tabs:tabs
      }
    },
    mounted() {
      if (this.tab == 'info') {
        this.activeTab = 0;
      }
      if (this.tab == 'images') {
        this.activeTab = 1;
      }
      if (this.tab == 'extras') {
        this.activeTab = 2;
      }
    }
  }
</script>