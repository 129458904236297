<template>
  <div>
    <portal to="title-page">
      <h1 v-if="landing">{{landing.name}}</h1>
    </portal>
    <landing-tabs v-if="landing" tab="info" :landing="landing">
      <v-card
        class="mx-auto elevation-1"
      >
        <Loading :active.sync="isLoading" :is-full-page="false"></Loading>
        <v-card-title >
          <v-icon
            large
            left
          >
            mdi-pencil
          </v-icon>
          <span class="title font-weight-light">Datos</span>
          <v-btn small @click.stop="dialogAddLang.show = true" color="primary" class="ml-auto"><v-icon>mdi-plus</v-icon> lenguaje</v-btn>
          <v-btn small class="ml-2" @click="$router.push({name: 'AdminCMSLandings'})"><v-icon>mdi-arrow-left</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row class="mb-4">
              <v-col cols="12" md="3" class="py-0">
                <v-select
                  v-if="registerLanguages != null"
                  v-model="language"
                  :items="registerLanguages"
                  item-text="language"
                  return-object
                  prepend-icon="mdi-flag"
                  :rules="[v => !!v || 'El idioma es requerido']"
                  label="Seleccione el idioma"
                  required
                  @change="changeLang"
                ></v-select>
              </v-col>
              <v-col cols="12" md="9" class="">
                <v-text-field
                  color="#f88511"
                  v-model="url"
                  required
                  :rules="[rules.required]"
                  :label="labelUrl"
                  prepend-icon="mdi-web"
                  
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row class="mb-4">
              <v-col md="8" class="">
                <v-text-field
                  color="#f88511"
                  v-model="landingLanguage.name"
                  required
                  :rules="[rules.required]"
                  label="Nombre"
                  prepend-icon="mdi-text"
                  dense
                ></v-text-field>
              </v-col>
              <v-col md="4" class="">
                <v-select
                  v-model="landing.status"
                  :items="[{text:'Activo', value: 'active'},{text:'Inactivo', value: 'inactive'}]"
                  item-text="text"
                  item-value="value"
                  label="Status"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4" class="mb-3">
                <v-select
                  v-model="landing.type"
                  :items="[{text:'Registro', value: 'register'},{text:'Tours', value: 'tours'}]"
                  item-text="text"
                  item-value="value"
                  prepend-icon="mdi-form-select"
                  label="Tipo de landing page"
                  dense
                  disabled
                ></v-select>
              </v-col>
              <v-col v-if="landing.type == 'tours' && landing.tours" md="12" class="">
                <v-combobox
                  v-model="landing.tours"
                  :items="tours"
                  label="Tours"
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi-format-list-numbered"
                  return-object
                  multiple
                  dense
                  chips
                ></v-combobox>
              </v-col>
              <v-col md="12" class="py-0">
                <v-textarea
                  class=""
                  v-model="landingLanguage.metadescription"
                  label="Metadescripcion"
                  rows="2"
                  counter
                  hint="Se recomienda maximo 160 caracteres"
                  prepend-icon="mdi-comment"
                ></v-textarea>
              </v-col>
              <v-col md="12" class="py-0">
                <v-text-field
                  color="#f88511"
                  v-model="landingLanguage.title"
                  required
                  :rules="[rules.required]"
                  label="Titulo"
                  prepend-icon="mdi-format-title"
                  dense
                ></v-text-field>
              </v-col>
              <v-col md="12" class="py-0">
                <p class="mt-5">Descripcion Titulo</p>
                <vue-editor
                  :editorOptions="editorSettings"
                  :class="landingLanguage.titleDescription != null && landingLanguage.titleDescription == '' ? 'error' : ''"
                  color="#f88511"
                  id="titleDescription"
                  v-model="landingLanguage.titleDescription"
                  :editor-toolbar="customToolbar"
                  auto-grow
                  outlined
                  rows="1"
                  dense
                ></vue-editor>
              </v-col>
              <v-col md="12" class="py-0">
                <p class="mt-5">Subtitulo</p>
                <vue-editor
                  :editorOptions="editorSettings"
                  :class="landingLanguage.subtitle != null && landingLanguage.subtitle == '' ? 'error' : ''"
                  color="#f88511"
                  id="subtitle"
                  v-model="landingLanguage.subtitle"
                  :editor-toolbar="customToolbar2"
                  auto-grow
                  outlined
                  rows="1"
                  dense
                ></vue-editor>
              </v-col>
              <!-- <v-col md="12" class="py-0">
                <vue-editor
                v-model="content"
                ref="editortest"
                :editor-options="editorSettings"
                @text-change="updateValue" useCustomImageHandler ></vue-editor>
              </v-col> -->
              <v-col md="12" class="py-0">
                <p class="mt-5">Descripcion</p>
                <vue-editor
                  :class="landingLanguage.description != null && landingLanguage.description == '' ? 'error' : ''"
                  color="#f88511"
                  id="description"
                  v-model="landingLanguage.description"
                  :editor-toolbar="customToolbar3"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  dense
                ></vue-editor>
              </v-col>
              <v-col md="12" class="my-4">
                <v-btn
                  :disabled="disableBtnAdd"
                  color="primary"
                  class="mr-4"
                  @click="validateForm('add')"
                >
                  <v-icon>mdi-content-save</v-icon> Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </landing-tabs>
    <v-dialog
      v-model="dialogAddLang.show"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Seleccione</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="formlang"
            v-model="validFormLang"
            lazy-validation
          >
            <v-select
              v-if="availableLanguages != null"
              v-model="newLang"
              :items="availableLanguages"
              item-text="name"
              return-object
              prepend-icon="mdi-flag"
              :rules="[v => !!v || 'El idioma es requerido']"
              label="Seleccione el idioma"
              required
            ></v-select>
            <p>Una vez seleccionado el lenguaje haga click en agregar y cuando se cierre esta ventana edite los datos.</p>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="dialogAddLang.show = false"
          >
            Cerrar
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!validFormLang"
            @click="addLang"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Vue from "vue";
import Landing from "@/models/Landing.js";
import Language from "@/models/Language.js";
import LandingTabs from "@/components/admin/LandingTabs";
import {DataConverter} from "@/FirestoreDataConverters/LandingLanguage.js";
import Tour from "@/models/Tour.js";
import { VueEditor, Quill  } from "vue2-editor";
import htmlEditButton from "quill-html-edit-button";


Quill.register("modules/htmlEditButton", htmlEditButton)
//https://github.com/benwinding/quill-html-edit-button
Vue.use('landing-tabs', LandingTabs);
export default {
  name: 'AdminCMSLandingEdit',
  components: { LandingTabs, VueEditor },
  created () {
    this.$store.commit('SET_LAYOUT', 'admin')
  },
  data() {
    return {
      validFormInit:true,
      validForm:true,
      isLoading:true,
      landingModel:new Landing,
      languageModel:new Language,
      language:null,
      tours:null,
      unsubscribeLandingUrl:null,
      unsubscribeLangs:null,
      registerLanguages:null,
      availableLanguages:null,
      landing:null,
      tourModel:new Tour,
      newLang:null,
      selectedLanguageId:'es',
      url:'',
      validFormLang:true,
      landingLanguage:{
        name:'',
        metadescription:'',
        description:'',
        titleDescription:'',
        subtitle:'',
      },
      dialogAddLang:{
        show:false
      },
      rules: {
        required: (v) => !!v || "El campo es obligatorio"
      },
      snackbar:{
        show:false,
        color:'',
        message:''
      },
      editorSettings:{
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["showHtml"]
            ],
            handlers: {
              showHtml: () => {
                if (this.txtArea.style.display === "") {
                  const html = this.txtArea.value;
                if (html === '<p><br/></p>') {
                  this.html = null;
                } else {
                  this.html = html.replace(new RegExp('<p><br/>', 'g'), '<p>')
                }
                  this.quill.pasteHTML(html);
                }
                this.txtArea.style.display =
                  this.txtArea.style.display === "none" ? "" : "none";
              }
            }
          }
        }
        /*modules: {
         htmlEditButton: {
            buttonHTML: 'HTML'
          }
        }*/
      },
      customToolbar: [
        ["bold", "italic", "underline"],
      ],
      customToolbar2: [
        ["bold", "italic", "underline"],
      ],
      customToolbar3: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      //toolbarOptions,
      content: "",
      rawContent: ""
    }
  },
  mounted() {
    this.landingId = this.$route.params.id;
    this.languageModel.getAll().onSnapshot(snap => {
      this.systemLanguages = [];
      snap.forEach( doc => {
        this.systemLanguages.push(doc.data());
      })
      this.setAvailableLanguages();
    })
    this.getUrl();
    this.landingModel.findByLandingId(this.landingId).onSnapshot((doc) => {
      this.ref = doc.ref;
      this.landing = doc.data();
      if (this.unsubscribeLangs) {
        this.unsubscribeLangs();
      }
      this.getLangs();
      this.changeType()
      /*this.$nextTick(()=>{
        console.log('this.$refs.editor', this.$refs);
        console.log('this.$refs.ptest', this.$refs.ptest);
        console.log('this.$refs.editortest', this.$refs['editortest']);
        this.quill = this.$refs.editortest.quill;
        this.txtArea = document.createElement("textarea");
        this.txtArea.style.cssText =
          "width: 100%;margin: 0px;background: rgb(29, 29, 29);box-sizing: border-box;color: rgb(204, 204, 204);font-size: 15px;outline: none;padding: 20px;line-height: 24px;font-family: Consolas, Menlo, Monaco, &quot;Courier New&quot;, monospace;position: absolute;top: 0;bottom: 0;border: none;display:none;resize: none;";
        
        const htmlEditor = this.quill.addContainer("ql-custom");
        htmlEditor.appendChild(this.txtArea);
        
        this.quill.on("text-change", () => {
          var html = this.quill.getHTML();
          this.txtArea.value = html;
        });
        this.content = this.value;
      });*/
      
    });


  },
   watch: {
    content() {
      this.rawContent = this.content;
    },
    rawContent() {
      this.content = this.rawContent;
    }
  },
  methods:{
    /*updateValue () {
      this.$emit('input', this.$refs.editortest.quill.getHTML())
    },*/
    changeType() {
      if (this.landing.type == 'tours' && this.tours === null) {
        this.isLoading = true
        this.tourModel.all().onSnapshot((qSnap) => {
          this.tours = [];
          this.isLoading = false
          qSnap.forEach((doc) => {
            this.tours.push({
              id:doc.data().id,
              name:doc.data().name,
            });
          });
        })
      }
    },
    setAvailableLanguages() {
      this.availableLanguages = [];
      if (this.systemLanguages !== null) {
        if (this.registerLanguages !== null) {
          for(var i in this.systemLanguages) {
            let langId = this.systemLanguages[i].id;
            let exist = this.registerLanguages.find(item => item.id == langId);
            if (!exist) {
              this.availableLanguages.push(this.systemLanguages[i]);
            }
          }
        } else {
          this.availableLanguages = this.systemLanguages;
        }
      }
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'green';
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'red';
    },
    changeLang() {
      this.selectedLanguageId = this.language.id;
      this.landingLanguage = this.language;
      this.getUrl();
    },
    addLang() {
      if (this.$refs.formlang.validate()) {
        let loading = this.$loading.show();
        if (this.unsubscribeLangs) {
          this.unsubscribeLangs();
        }
        this.ref.collection('landingLanguages').doc(this.newLang.id).set({
          name:'',
          title:'',
          metadescription:'',
          description:'',
          language:this.newLang.name,
        }).then(() => {
          this.getLangs();
          this.selectedLanguageId = this.newLang.id;
          loading.hide();
          this.showSnackbarSuccess('El lenguaje se agrego correctamente, ingrese la informacion correspendiente');
          this.dialogAddLang.show = false;
        });
      }
    },
    getUrl() {
      if (this.unsubscribeLandingUrl) {
        this.unsubscribeLandingUrl();
      }
      this.unsubscribeLandingUrl = this.landingModel.findUrlByLanding(this.landingId, this.selectedLanguageId)
      .onSnapshot((snap) => { 
        if (!snap.empty) {
          this.url = snap.docs[0].data().url;
        } else {
          this.url = '';
        }
      });
    },
    getLangs() {
      this.unsubscribeLangs = this.ref.collection('landingLanguages').withConverter(DataConverter).onSnapshot(docs => {
        this.registerLanguages = []
        var index, i = 0;
        docs.forEach(doc => {
          this.registerLanguages.push(doc.data());
          if (doc.data().id == this.selectedLanguageId) {
            index = i;
          }
          i++;
        })
        this.language = this.registerLanguages[index];
        this.landingLanguage = this.registerLanguages[index];
        this.setAvailableLanguages();
        this.isLoading = false;
      })
    },
    validateForm() {
      this.validFormInit = false;
      this.landingLanguage.description = this.landingLanguage.description == null ? '' : this.landingLanguage.description;

      if (this.$refs.form.validate() && !this.disableBtnAdd) {
        let loading = this.$loading.show();
        let prefix = this.language.id == 'es' ? '' : `/${this.language.id}`;
        let fullUrl = `${prefix}/landings/${this.url}`;
        this.landingModel.findUrl(fullUrl, this.language.id).get().then(async(snap) => {
          let landingIdUrl = snap.empty ? null : snap.docs[0].data().landingId;
          var landingData = this.landingLanguage.toObject()
          console.log('landingData', landingData);
          
          if (landingIdUrl == this.landingId || snap.empty) {
            let ldata = {
              type:this.landing.type,
            };
            if(this.landing.type == 'tours') {
              ldata.tours = this.landing.tours
            }
            if(this.language.id == 'es') {
              ldata.name = this.landingLanguage.name
            }
            this.ref.update(ldata).then(() => {
              this.ref.collection('landingLanguages').doc(this.landingLanguage.id).update(landingData).then(() => {
                this.ref.collection('landingUrls').doc(this.landingId+this.language.id).set({
                  landingId:this.landingId,
                  fullUrl:fullUrl,
                  url:this.url,
                  language:this.language.id,
                  deleted:0
                }).then(() => {
                  loading.hide();
                  this.showSnackbarSuccess('La landing se actualizo correctamente');
                })
              })
              .catch(error => {
                console.log(error);
                loading.hide();
                this.showSnackbarError('Error al actualizar el lenguaje');
              })
            })
            .catch(error => {
              console.log(error);
              loading.hide();
              this.showSnackbarError('Error al actualizar la alnding');
            })
          } else {
            this.showSnackbarError('La url "'+this.url+ '" ya esta registrada, ingrese otra');
            loading.hide();
          }
        });
      }
    },
  },
  computed:{
    labelUrl() {
      if (this.url && this.language) {
        const lang = this.language.id == 'es' ? '' : this.language.id + `/`
        return `Url en `
          +(this.language ? this.language.language : '')
          + `: ${process.env.VUE_APP_API_URL}/${lang}landings/`
          + this.url
      }
      return `Url en ` +(this.language ? this.language.language : '')
    },
    disableBtnAdd() {
      if (this.validFormInit) {
        return false;
      }
      if (!this.landingLanguage.description) {
        return true;
      }
      return false;
    }
  }
}
</script>
<style>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
#app .quillWrapper.error {
  background-color: transparent !important;
}
.quillWrapper.error > div {
  border-color: #ff5252;
  background-color: transparent;
}
#titleDescription, #subtitle {
  height: 100px;
}
#description {
  height: 500px;
}
.ql-editor {
  min-height: 50px !important;
}
</style>